import logo from '../assets/img/logos/airmontenegro.svg';
import logoWhite from '../assets/img/logos/airmontenegro.svg';
import rollingBanner1Web from '../assets/img/banners/airserbia/1920x400.jpg';
import rollingBanner2Web from '../assets/img/homeBanner/RollingBanner2.jpg';
import rollingBanner3Web from '../assets/img/homeBanner/RollingBanner3.jpg';
import rollingBanner4Web from '../assets/img/homeBanner/RollingBanner4.jpg';
import rollingBanner5Web from '../assets/img/homeBanner/RollngBanner5.jpg';
import rollingBanner1Mob from '../assets/img/banners/airserbia/Phone_WL_Home [Banner1].jpg';
import rollingBanner1Tab from '../assets/img/banners/airserbia/iPad_WL_Home [Banner4].jpg';
import rollingBanner2Mob from '../assets/img/homeBanner/RollingBanner2MobileWeb.jpg';
import rollingBanner3Mob from '../assets/img/homeBanner/RollingBanner3MobileWeb.jpg';
import rollingBanner4Mob from '../assets/img/homeBanner/RollingBanner4MobileWeb.jpg';
import rollingBanner5Mob from '../assets/img/homeBanner/RollingBanner5MobileWeb.jpg';
import localization from '../localization';
var banner = require('../assets/img/banners/airserbia_banner.jpg');
var favicon = require('../assets/img/favicon.ico');

export default {
  name: 'Air Montenegro',
  clientName: 'Air Montenegro',
  config: {
    showMapsByDefault: false,
    googleAnalyticsKey: 'GTM-TC463Q3',
    clientGAKey: 'GTM-TC463Q3',
    GTMID: 'GTM-TC463Q3',
    usePrimaryColoredBanner: false,
    dontUseWhiteLogoOnHomepage: true,
    customLogoHeight: '70px',
    contactUsLink: 'http://tripadmit.com/',
    supportLink: 'http://tripadmit.com/',
    aboutUsLink: 'http://tripadmit.com/',
    cookiePolicyLink: 'http://tripadmit.com/',
    privacyPolicyLink: 'http://tripadmit.com/',
    tosLink: 'http://tripadmit.com/',
    overrideActivityProviderText: 'Activity brought to you by Air Montenegro',
  },
  meta: {
    title: 'TripAdmit - Tours and Activities',
    description: 'Connecting Experiences',
    home: '/home',
  },
  colors: {
    primary: '#212E5F',
    secondary: '#002855',
    inactive: '#BCBCBC',
  },
  fonts: {
    colors: {
      primary: '#212E5F',
      secondary: '#002855',
      inactive: '#BCBCBC',
    },
    primary: 'MontserratRegular',
    regular: 'MontserratRegular',
    light: 'MontserratRegular',
    bold: 'MontserratBold',
  },
  styles: {
    airserbiaButton: {
      borderRadius: '4px!important',
    },
    pickerControlButton: {
      background: '#212E5F',
      borderRadius: '4px',
      width: '32px',
      height: '32px',
      padding: '0',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      color: '#fff',
    },
    pickerCounter: {
      background: 'none',
      border: '0',
      width: '36px',
      height: '32px',
      padding: '0',
      textAlign: 'center',
      margin: '0 2px',
    },
  },
  bannerImages: {
    images: [
      {
        web: rollingBanner1Web,
        mob: rollingBanner1Mob,
        tab: rollingBanner1Tab,
        text: localization.rollingBanner.static,
        link: '',
      },
    ],
  },

  assets: {
    logo,
    logoWhite,
    favicon,
    banner,
  },
  currency: {
    default: 'EUR',
    defaultWithSymbol: 'EUR €',
    available: {
      EUR: 'EUR €',
      AED: 'AED د.إ',
      USD: 'USD $',
      GBP: 'GBP £',
      RSD: 'RSD din',
    },
  },
  language: {
    default: 'en',
    available: ['en'],
  },
};
